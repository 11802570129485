<template>
    <Dialog contentClass="py-0" :visible="true" :modal="true" :closable="false" :style="{ width: '65vw' }">
        <template #header>
            <h5>{{ visitLocationDetail.name }}</h5>
        </template>
        <div class="container container-width border-b border-t p-3">
            <div class="row">
                <div class="col-md-4">
                    <span class="fw-bold mb-3">Building</span>
                    <p class="mb-0">{{ visitLocationDetail?.buildingName }} ({{ visitLocationDetail?.buildingId }})</p>
                    <!-- Need to store building address in collection -->
                    <address v-if="visitLocationDetail.buildingAddress">
                        {{ visitLocationDetail.buildingAddress.line_1 }}&nbsp; {{ visitLocationDetail.buildingAddress.line_2 }}&nbsp;
                        {{ visitLocationDetail.buildingAddress.city }}&nbsp; {{ visitLocationDetail.buildingAddress.state }}&nbsp;
                        {{ visitLocationDetail.buildingAddress.zip }}
                    </address>
                </div>
                <div class="col-md-4">
                    <span class="fw-bold mb-3">Primary Contact</span>
                    <div v-if="visitLocationDetail.primaryContact">
                        <p class="mb-0">{{ visitLocationDetail.primaryContact.name }}</p>
                        <p class="mb-0">{{ visitLocationDetail.primaryContact.email }}</p>
                        <p>{{ visitLocationDetail.primaryContact.phone }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <span class="fw-bold mb-3">Required Slots</span>
                    <div class="required-slots">
                        <div v-for="slots in visitLocationDetail.purposeList" :key="slots">
                            <template v-if="slots">{{ slots.name }} ({{ slots.duration }})</template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <span class="fw-bold">Covered Locations</span>
                <CoveredLocations :coveredLocations="visitLocationDetail.coveredLocations" />
            </div>
        </div>
        <template #footer>
            <Button label="OK" @click="this.$emit('toggleModal')" />
        </template>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { CoveredLocations } from '../../../visits/components/shared';

export default {
    name: 'VisitLocationDetails',
    components: {
        Button,
        Dialog,
        CoveredLocations
    },
    props: {
        visitLocationDetail: Object
    },
    data() {
        return {};
    },
    methods: {
        onExpand(data) {
            data.expand = !data.expand;
        }
    },
    emit: ['toggleModal']
};
</script>

<style scoped>
.required-slots {
    max-height: 5rem;
    overflow: auto;
}
</style>
