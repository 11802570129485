<template>
    <Dialog header="Filter" @hide="$emit('closeFilter')" v-model:visible="showFilterPopup" :style="{width: '25.25rem'}"
        position="topright" :draggable="false">
        <div class="size14 color-lavender-purple font-weight-500 mb-4" role="button" @click="clearFilters()">CLEAR FILTER</div>
        <div class="text-muted fs-5 font-weight-500 mb-2">State</div>
        <div class="field-checkbox margin-top-12 mb-5">
            <Dropdown class="w-100" v-model="selectedState" :options="VisitLocationState"
            optionLabel="name" optionValue="code" placeholder="Select a State" />
        </div>
        <div class="text-muted fs-5 font-weight-500 mb-2">Locations</div>
        <div class="field-checkbox margin-top-12">
            <LocationSelection :showTree="true" @selectTree="applyTree" :selectedLocations="selectedLocations"/>
        </div>
        <Button class="filterText button-size justify-content-center button-color-lavender-purple mt-4 w-100"
        @click="applyfilter()">APPLY</Button>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import { VisitLocationState } from '../../../../shared/enums/visit_location-state';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import 'primeicons/primeicons.css';
import LocationSelection from '../../../../shared/LocationSelection.vue';
export default {
    name: 'VisitLocationFilter',
    components: {
        Dialog,
        Button,
        Dropdown,
        LocationSelection
    },
    props: {
        showFilterPopup: Boolean
    },
    data() {
        return {
            selectedState: null,
            VisitLocationState: Object.keys(VisitLocationState)
                .map(key => ({ name: String(key), code: VisitLocationState[key] })),
            selectedLocations: [],
            filterObj: {}
        };
    },
    methods: {
        clearFilters() {
            this.selectedState = null;
            this.selectedLocations = [];
            this.filterObj = {};
            this.applyfilter();
        },
        applyfilter() {
            this.$emit('applyFilter', parseInt(this.selectedState), this.filterObj);
        },
        applyTree(selections, filterObj) {
            this.selectedLocations = selections;
            this.filterObj = filterObj;
        }
    }
};
</script>
