<template>
    <Dialog :header="header" :closable="false" :visible="visible" :modal="true" :style="{ width: '50vw' }">
        <DataTable
            :value="dataList"
            v-model:selection="selectedData"
            :selectionMode="selectionMode"
            :dataKey="dataKey"
            :metaKeySelection="false"
            responsiveLayout="scroll"
            :rowClass="rowStyle"
            :loading="loading"
            @rowUnselectAll="rowUnselectAll"
        >
            <Column selectionMode="multiple" headerStyle="width: 3em" v-if="selectionMode == 'multiple'" />
            <Column field="name" header="Name" />
        </DataTable>
        <template #footer>
            <Button label="Cancel" @click="this.$emit('closePopup')" class="p-button-outlined float-end me-3" />
            <Button
                label="OK"
                @click="this.$emit('selectionDone', { data: this.selectedData, name: opName })"
                class="float-end me-3"
            />
        </template>
    </Dialog>
</template>
<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
export default {
    name: 'MultiSelect',
    components: {
        Button,
        Column,
        Dialog,
        DataTable
    },
    data() {
        return {
            selectedData: this.selectedItems(),
            selectedDataBackup: [...this.selectedItems().filter(b => b.disabled)]
        };
    },
    props: {
        visible: Boolean,
        header: String,
        dataList: Array,
        selectionMode: {
            type: String,
            default: 'multiple'
        },
        selectedItems: Function,
        opName: String,
        dataKey: {
            type: String,
            default: 'id'
        },
        loading: Boolean
    },
    methods: {
        rowStyle(data) {
            return data.disabled ? 'pe-none bg-info text-white' : '';
        },
        rowUnselectAll(event) {
            setTimeout(() => (this.selectedData = [...this.selectedDataBackup]), 1);
        }
    }
};
</script>
