<template>
    <Dialog :header="selectionMode == 'multiple' ? 'Select Members' : 'Select Member'" :closable="false"
    visible="true" modal=true :style="{width: '50vw'}">
        <DataTable :value="selectionMode == 'multiple' ? membersList : teamLeadList" v-model:selection="selectedMembers"
        :selectionMode="selectionMode" dataKey="id" :metaKeySelection="false" responsiveLayout="scroll" :style="{'max-height': '40vh'}">
            <Column selectionMode="multiple" headerStyle="width: 3em" v-if="selectionMode == 'multiple'"></Column>
            <Column class="width-dropdown-item" field="name" header="Name"></Column>
            <Column class="width-dropdown-item" field="email" header="Email"></Column>
            <Column class="width-dropdown-item" field="role" header="Role">
                <template #body="slotProps">
                    {{getRole(slotProps.data.role)}}
                </template>
            </Column>
        </DataTable>
        <template #footer>
            <Button label="CANCEL" @click="this.$emit('closePopup');" class="p-button-outlined float-end me-3" />
            <Button label="OK" @click="this.$emit('selectionDone', this.selectedMembers);" class="float-end me-3"
            :disabled="isFetchingFailed"/>
        </template>
    </Dialog>
</template>
<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { toasterTime, messages } from '../../shared/constants/constants';
import { UserRole } from '../enums';
import { UserService } from '../../apis';
export default {
    name: 'UserSelection',
    components: {
        Dialog,
        DataTable,
        Column,
        Button
    },
    data() {
        return {
            displayDialog: false,
            teamLeadList: null,
            membersList: null,
            isFetchingFailed: false
        };
    },
    props: {
        selectionMode: String,
        selectedMembers: Object,
        listRole: Array
    },
    created() {
        UserService.getTeamLeadList().then(value => {
            this.teamLeadList = value;
        }).catch(err => {
            this.isFetchingFailed = true;
            this.$toast.add({ severity: 'error', closable: false,
                detail: messages.teamValidation.teamsLeadListFetchingFailed, life: toasterTime });
        });
        UserService.getMemberList(this.listRole).then(value => {
            this.membersList = value;
        }).catch(error => {
            this.isFetchingFailed = true;
            this.$toast.add({ severity: 'error', closable: false,
                detail: messages.teamValidation.memberListFetchingFailed, life: toasterTime });
        });
    },
    methods: {
        getRole(roleId) {
            return Object.keys(UserRole).find(id => UserRole[id] === roleId);
        }
    }

};
</script>
