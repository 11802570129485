<template>
    <template v-for="location in coveredLocations" :key="location.locationId">
        <div class="row">
            <div class="col-md-12 d-flex">
                <Button
                    :disabled="!location.spoc || !Object.keys(location.spoc).length"
                    :icon="location.expand ? 'pi pi-minus' : 'pi pi-plus'"
                    class="p-button-rounded p-button-text"
                    @click="location.expand = !location.expand"
                />
                <div class="d-inline-flex pt-1">{{ location.locationName }} ({{ location.locationId }})</div>
            </div>
        </div>
        <div class="row" v-show="location.expand">
            <small class="col-md-12 ps-5 pe-0 text-sm text-muted">
                SPOC: {{ location.spoc.name }}
                <template v-if="location.spoc.emai && location.spoc.phone">({{ location.spoc.email }}, {{ location.spoc.phone }})</template>
                <template v-else-if="location.spoc.emai || location.spoc.phone">
                    ({{ location.spoc.email }}{{ location.spoc.phone }})
                </template>
            </small>
        </div>
    </template>
</template>
<script>
import Button from 'primevue/button';
export default {
    name: 'CoveredLocations',
    components: {
        Button
    },
    props: {
        coveredLocations: {
            type: Array,
            required: true
        }
    }
};
</script>
