<template>
    <Dialog
        :header="visitLocationData ? 'Edit Visit Location' : 'Create Visit Location'"
        :visible="true"
        :modal="true"
        :closable="false"
        :style="{ width: '90vw' }"
    >
        <div class="container-fluid mt-3 mb-5">
            <div class="row">
                <div :class="visitLocationData ? 'col-md-6' : 'col-md-8'">
                    <label for="visitName" class="fw-bold">Visit Location Name<span class="text-danger ms-1">*</span></label>
                    <InputText id="visitName" class="team-name w-100" type="text" v-model="v$.form.name.$model" />
                    <span v-if="v$.form.name.$error && v$.form.name.required && v$.form.name.required.$invalid" class="text-danger">
                        {{ messages.visitLocationValidation.visitLocationNameErrorMessage }}
                    </span>
                </div>
                <div :class="visitLocationData ? 'col-md-3' : 'col-md-4'">
                    <RequiredSlots :SlotsList="form.purposeList" @addSlots="addSlots" @removeSlot="removeSlot" />
                    <span
                        class="text-danger"
                        v-if="v$.form.purposeList.$error && v$.form.purposeList.required && v$.form.purposeList.required.$invalid"
                    >
                        {{ messages.visitLocationValidation.purposeErrorMessage }}
                    </span>
                </div>
                <div v-if="visitLocationData" class="col-md-3">
                    <label class="fw-bold ms-3">Status</label>
                    <div class="ms-3">
                        <RadioButton id="active" name="active" :value="visitLocationState.Active" v-model="form.state" />
                        <label for="active" class="ms-2 me-3 fw-bold">Active</label>
                        <RadioButton id="archived" name="archived" :value="visitLocationState.Archived" v-model="form.state" />
                        <label for="archived" class="ms-2 fw-bold">Archived</label>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4">
                            <label for="borough" class="fw-bold">Borough<span class="text-danger ms-1">*</span></label>
                            <Dropdown
                                dataKey="id"
                                id="ddBorough"
                                :disabled="visitLocationData"
                                class="w-100"
                                v-model="v$.form.selectedBorough.$model"
                                :options="boroughList"
                                @change="onBoroughChange"
                                optionLabel="name"
                                placeholder="Select Borough"
                            />
                            <span
                                v-if="
                                    v$.form.selectedBorough.$error &&
                                    v$.form.selectedBorough.mustHaveId &&
                                    v$.form.selectedBorough.mustHaveId.$invalid
                                "
                                class="text-danger"
                            >
                                {{ messages.visitValidation.boroughErrorMessage }}
                            </span>
                        </div>
                        <div class="col-md-4">
                            <label for="district" class="fw-bold">District<span class="text-danger ms-1">*</span></label>
                            <Dropdown
                                dataKey="id"
                                id="ddDistrict"
                                :disabled="visitLocationData"
                                class="w-100"
                                v-model="v$.form.selectedDistrict.$model"
                                :options="filteredDistricts"
                                @change="onDistrictChange"
                                optionLabel="name"
                                placeholder="Select District"
                            />
                            <span
                                v-if="
                                    v$.form.selectedDistrict.$error &&
                                    v$.form.selectedDistrict.mustHaveId &&
                                    v$.form.selectedDistrict.mustHaveId.$invalid
                                "
                                class="text-danger"
                            >
                                {{ messages.visitValidation.districtErrorMessage }}
                            </span>
                        </div>
                        <div class="col-md-4">
                            <label for="building" class="fw-bold">Building<span class="text-danger ms-1">*</span></label>
                            <Dropdown
                                dataKey="id"
                                id="ddBuilding"
                                :disabled="visitLocationData"
                                class="w-100"
                                v-model="v$.form.selectedBuilding.$model"
                                :options="buildingList"
                                @change="onBuildingChange"
                                optionLabel="name"
                                placeholder="Select Building"
                                panelClass="dropdown-panel"
                            >
                                <template #option="slotProps">
                                    <span>{{ `${slotProps.option.id} - ${slotProps.option.name}` }}</span>
                                </template>
                            </Dropdown>
                            <span
                                v-if="
                                    v$.form.selectedBuilding.$error &&
                                    v$.form.selectedBuilding.mustHaveId &&
                                    v$.form.selectedBuilding.mustHaveId.$invalid
                                "
                                class="text-danger"
                            >
                                {{ messages.visitLocationValidation.buildingErrorMessage }}
                            </span>
                        </div>
                    </div>
                    <div class="mt-4 row">
                        <div class="col-md-12">
                            <label for="address" class="fw-bold">Address</label><br />
                            <label id="address" v-if="form.selectedBuilding">
                                {{
                                    form.selectedBuilding?.address?.line_1 +
                                    ' ' +
                                    form.selectedBuilding?.address?.line_2 +
                                    ' ' +
                                    form.selectedBuilding?.address?.city +
                                    ', ' +
                                    form.selectedBuilding?.address?.state +
                                    ' ' +
                                    form.selectedBuilding?.address?.zip
                                }}
                            </label>
                        </div>
                    </div>
                    <div class="mt-4 row">
                        <label class="fw-bold">Primary Contact</label>
                        <div class="col-md-4">
                            <label for="name" class="fw-bold">Name<span class="text-danger ms-1">*</span></label>
                            <InputText id="name" class="w-100" type="text" v-model="v$.form.primaryContact.name.$model" />
                            <span
                                class="text-danger"
                                v-if="
                                    v$.form.primaryContact.name.$error &&
                                    v$.form.primaryContact.name.required &&
                                    v$.form.primaryContact.name.required.$invalid
                                "
                            >
                                {{ messages.visitValidation.primaryContactNameErrorMessage }}
                            </span>
                        </div>
                        <div class="col-md-4">
                            <label for="email" class="fw-bold">Email<span class="text-danger ms-1">*</span></label>
                            <InputText id="email" class="w-100" type="text" v-model="v$.form.primaryContact.email.$model" />
                            <span
                                class="text-danger"
                                v-if="
                                    v$.form.primaryContact.email.$error &&
                                    v$.form.primaryContact.email.required &&
                                    v$.form.primaryContact.email.required.$invalid
                                "
                            >
                                {{ messages.visitValidation.primaryContactEmailErrorMessage }}
                            </span>
                            <span
                                class="text-danger"
                                v-if="
                                    v$.form.primaryContact.email.$error &&
                                    v$.form.primaryContact.email.email &&
                                    v$.form.primaryContact.email.email.$invalid
                                "
                            >
                                {{ messages.visitValidation.primaryContactEmailInvalid }}
                            </span>
                        </div>
                        <div class="col-md-4">
                            <label for="phone" class="fw-bold">Phone<span class="text-danger ms-1">*</span></label>
                            <InputMask
                                id="phone"
                                type="tel"
                                mask="+1-999-999-9999"
                                class="w-100"
                                v-model="v$.form.primaryContact.phone.$model"
                            />
                            <span
                                class="text-danger"
                                v-if="
                                    v$.form.primaryContact.phone.$error &&
                                    v$.form.primaryContact.phone.required &&
                                    v$.form.primaryContact.phone.required.$invalid
                                "
                            >
                                {{ messages.visitValidation.primaryContactPhoneErrorMessage }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <label class="fw-bold">Managers</label>
                    <Button icon="pi pi-plus" id="plusButton" @click="toggleManagerList" />
                    <table class="slots-div panelgrid">
                        <tr class="mb-1" v-for="(user, index) in form.managers" :key="index">
                            <td>{{ user.name + '(' + user.email + ')' }}</td>
                            <td>
                                <Button
                                    icon="pi pi-minus"
                                    @click="removeManager(index)"
                                    class="p-button-rounded p-button-danger p-button-text float-end"
                                    id="crossButton"
                                />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row mt-4">
                <!--<div class="col-md-8">-->
                    <label class="fw-bold ms-1">Locations ({{form.selectedCoveredLocation.length}})</label>
                    <div class="text-danger">
                        {{ this.validEmailCoveredLocation }}
                    </div>
                    <CoveredLocation
                        :locationList="locationList"
                        @onRowSelectUnSelect="onRowSelectUnSelect"
                        :visitLocationBuilding="visitLocationBuilding"
                        :selectedCoveredLocation="form.selectedCoveredLocation"
                    />
                    <span
                        class="text-danger"
                        v-if="
                            v$.form.selectedCoveredLocation.$error &&
                            v$.form.selectedCoveredLocation.required &&
                            v$.form.selectedCoveredLocation.required.$invalid
                        "
                    >
                        {{ messages.visitLocationValidation.coveredLocationErrorMessage }}
                    </span>
                <!--</div>-->
            </div>
        </div>
        <template #footer>
            <hr />
            <Button
                label="DELETE"
                @click="deleteVisit(visitLocationData.id)"
                class="p-button-danger float-start me-3"
                v-if="visitLocationData && isNavigationAllowed(NAVIGATIONCONST.DELETEVISITLOCATION)"
            />
            <Button label="SAVE" @click="createVisitLocation" :disabled="disableSaveBtn" />
            <Button label="CANCEL" @click="this.$emit('closeForm')" class="p-button-outlined me-3" />
        </template>
        <UserSelection
            :selectionMode="selectionMode"
            v-if="showManagerList"
            @selectionDone="managerSelectionDone"
            @closePopup="toggleManagerList"
            :selectedMembers="this.form.managers"
            :listRole="managerListRole"
        />
        <ProgressSpinner class="p-component-overlay position-fixed w-100 h-100" strokeWidth="8" v-if="spinner" />
    </Dialog>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import CoveredLocation from './CoveredLocations.vue';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import RadioButton from 'primevue/radiobutton';
import ProgressSpinner from 'primevue/progressspinner';
import { BuildingService, VisitLocationService, LocationService, UserService, VisitService } from '../../../../apis';
import Dropdown from 'primevue/dropdown';
import RequiredSlots from './RequiredSlots.vue';
import { toasterTime, messages, regexEmail, userActivities, toasterType } from '../../../../shared/constants/constants';
import UserSelection from '../../../../shared/popup/UserSelection.vue';
import { UserActivity, isNavigationAllowed } from '../../../../shared/utils';
import { VisitLocationState } from '../../../../shared/enums/index';
import { NAVIGATIONCONST, managerListRole } from '../../../../shared/constants';
import { v4 as uuidv4 } from 'uuid';

const mustHaveKey = param => value => value && value[param];
export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'CreateVisitLocation',
    components: {
        Button,
        CoveredLocation,
        Dialog,
        Dropdown,
        InputMask,
        InputText,
        ProgressSpinner,
        RadioButton,
        RequiredSlots,
        UserSelection
    },
    async mounted() {
        if (this.form.selectedBuilding) {
            this.getLocationList();
            this.visitLocationBuilding = this.visitLocations.filter(
                x => x.buildingId == this.form.selectedBuilding.id && x.id !== this.visitLocationData.id
            );
        }
        if (this.visitLocationData) {
            this.filteredDistricts = this.districtList.filter(x => x.boroughId == this.form.selectedBorough.id);
            this.getBuildingByBoroughAndDistrict(this.form.selectedBorough.id, this.form.selectedDistrict.id);
        }
    },
    props: {
        visitLocations: Array,
        visitLocationData: {
            type: Object,
            default: () => ({})
        },
        boroughList: Array,
        districtList: Array
    },
    data() {
        return {
            userActivities: userActivities,
            messages,
            buildingList: [],
            locationList: [],
            filteredDistricts: null,
            areEmailsInValid: false,
            form: {
                id: this.visitLocationData?.id,
                name: this.visitLocationData?.name || '',
                state: this.visitLocationData?.state || VisitLocationState.Active,
                purposeList: this.visitLocationData?.purposeList || [],
                selectedBorough: this.visitLocationData ?
                    { id: this.visitLocationData.boroughId, name: this.visitLocationData.boroughName } :
                    { id: '' },
                selectedDistrict: this.visitLocationData ?
                    { id: this.visitLocationData.districtId, name: this.visitLocationData.districtName } :
                    '',
                selectedBuilding: this.visitLocationData ?
                    {
                        id: this.visitLocationData.buildingId,
                        name: this.visitLocationData.buildingName,
                        address: this.visitLocationData.buildingAddress,
                        map_link: this.visitLocationData?.buildingMapLink
                    } :
                    '',
                managers: this.visitLocationData?.managers || [],
                primaryContact: this.visitLocationData?.primaryContact || { name: '', email: '', phone: '' },
                coveredLocations: this.visitLocationData ? this.visitLocationData.coveredLocations : [],
                selectedCoveredLocation: this.visitLocationData?.coveredLocations || []
            },
            isNavigationAllowed,
            managerListRole: managerListRole,
            NAVIGATIONCONST,
            showManagerList: false,
            showSlots: false,
            selectionMode: 'multiple',
            selectedList: null,
            spinner: false,
            validEmailCoveredLocation: '',
            visitLocationName: '',
            visitLocationBuilding: [],
            visitLocationState: VisitLocationState,
            disableSaveBtn: false
        };
    },
    validations() {
        return {
            form: {
                name: { required },
                selectedBorough: { mustHaveId: mustHaveKey('id') },
                selectedDistrict: { mustHaveId: mustHaveKey('id') },
                selectedBuilding: { mustHaveId: mustHaveKey('id') },
                primaryContact: {
                    name: { required },
                    email: { required, email },
                    phone: { required }
                },
                selectedCoveredLocation: { required },
                purposeList: { required }
            }
        };
    },
    methods: {
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        async getBuildingByBoroughAndDistrict(boroughId, districtId) {
            this.buildingList = await BuildingService.getBuildingByBoroughAndDistrict(boroughId, districtId);
        },
        onBoroughChange() {
            this.form.selectedDistrict = null;
            this.onDistrictChange();
            this.filteredDistricts = this.districtList.filter(x => x.boroughId == this.form.selectedBorough.id);
        },
        onDistrictChange() {
            this.form.selectedBuilding = null;
            this.buildingList = null;
            this.onBuildingChange();
            if (this.form.selectedDistrict) {
                this.getBuildingByBoroughAndDistrict(this.form.selectedBorough.id, this.form.selectedDistrict.id);
            } else {
                this.filteredDistricts = [];
            }
        },
        async onBuildingChange() {
            this.form.selectedCoveredLocation = [];
            if (this.form.selectedBuilding) {
               this.getLocationList();
               this.visitLocationBuilding = this.visitLocations.filter(x => x.buildingId == this.form.selectedBuilding.id);
            } else {
                this.locationList = [];
                this.visitLocationBuilding = [];
            }
        },
        async getLocationList() {
            const building = await BuildingService.getById(this.form.selectedBuilding.id);          
            this.locationList = await LocationService.getLocationByBuildingId(building.location);
        },
        async createVisitLocation() {
            this.validEmailCoveredLocation = '';
            this.disableSaveBtn = true;
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                this.disableSaveBtn = false;
                return;
            }
            this.areEmailsInValid = false;
            this.form.selectedCoveredLocation.forEach(element => {
                if (!element.spoc || element.spoc == {}) {
                    // to handle the previos pilot data
                    element.spoc = this.form.primaryContact;
                }
                if (!element.spoc.name) {
                    // to handle the previous pilot data
                    element.spoc.name = '';
                }
                if (!element.spoc.email) {
                    // to handle the previous pilot data
                    element.spoc.email = '';
                }
                if (!element.spoc.phone) {
                    // to handle the previous pilot data
                    element.spoc.phone = '';
                }
                if (element.spoc.email && !this.isValidEmail(element.spoc.email)) {
                    this.validEmailCoveredLocation = messages.visitLocationValidation.validEmailsRequired;
                    this.areEmailsInValid = true;
                    this.disableSaveBtn = false;
                    return;
                }
                if (
                    element.spoc.name.trim().length == 0 &&
                    element.spoc.email.trim().length == 0 &&
                    element.spoc.phone.trim().length == 0
                ) {
                    element.spoc = this.form.primaryContact;
                }
            });
            if (this.areEmailsInValid) {
                this.disableSaveBtn = false;
                return;
            }
            this.spinner = true;
            const data = {
                name: this.form.name,
                state: this.form.state,
                purposeList: this.form.purposeList,
                boroughId: this.form.selectedBorough?.id || '',
                boroughName: this.form.selectedBorough?.name || '',
                districtId: this.form.selectedDistrict?.id || '',
                districtName: this.form.selectedDistrict?.name || '',
                buildingId: this.form.selectedBuilding?.id || '',
                buildingName: this.form.selectedBuilding?.name || '',
                buildingAddress: this.form.selectedBuilding?.address || '',
                buildingMapLink: this.form.selectedBuilding?.map_link || '',
                managers: this.form.managers,
                primaryContact: this.form.primaryContact,
                coveredLocations: this.form.selectedCoveredLocation
            };
            if (!this.visitLocationData) {
                const createVisitLocationData = {
                    ...data,
                    info: {
                        ...UserActivity.getActivity(userActivities.created),
                        ...UserActivity.getActivity(userActivities.updated)
                    }
                };
                try {
                    const { id } = await VisitLocationService.create(createVisitLocationData);
                    this.showToaster(messages.visitLocationValidation.visitLocationCreatedSuccess, toasterType.success);
                    this.rolePromise = [];
                    data.managers.forEach(val => {
                        this.rolePromise.push(UserService.updateRoleData(val.id, id));
                    });
                    Promise.all(this.rolePromise);

                    this.$emit('closeForm');
                } catch (err) {
                    this.showToaster(messages.visitLocationValidation.visitLocationCreateFailed, toasterType.error);
                } finally {
                    this.spinner = false;
                    this.disableSaveBtn = false;
                }
            } else {
                const updateVisitLocationData = {
                    ...data,
                    info: {
                        ...UserActivity.getActivity(userActivities.updated),
                        ...UserActivity.getActivity(userActivities.archived, this.form.state)
                    }
                };
                const existingRoleData = await UserService.getRoleData(this.form.id);
                this.rolePromise = [];
                data.managers.forEach(val => {
                    if (!existingRoleData.find(x => x.id === val.id)) {
                        this.rolePromise.push(UserService.updateRoleData(val.id, this.form.id));
                    }
                });
                existingRoleData.forEach(val => {
                    if (!data.managers.find(x => x.id === val.id)) {
                        this.rolePromise.push(UserService.deleteRoleData(val.id, this.form.id));
                    }
                });
                Promise.all(this.rolePromise);
                try {
                    await VisitLocationService.update(this.form.id, updateVisitLocationData);
                    this.showToaster(messages.visitLocationValidation.visitLocationUpdateSuccess, toasterType.success);
                    this.$emit('closeForm');
                } catch (err) {
                    this.showToaster(messages.visitLocationValidation.visitLocationUpdateFailed, toasterType.error);
                } finally {
                    this.spinner = false;
                    this.disableSaveBtn = false;
                }
            }
        },
        deleteVisit(id) {
            this.$confirm.require({
                message: messages.visitLocationValidation.deleteConfirmation.message,
                header: messages.visitLocationValidation.deleteConfirmation.header,
                accept: async () => {
                    this.spinner = true;
                    try {
                        const filteredVisit = await VisitService.getAll({'visitLocationId': { matchMode: '==', value: id}});
                        if(filteredVisit.length > 0) {
                            this.showToaster(messages.visitLocationValidation.visitLocationAssigned, toasterType.error);
                        } else {
                            await VisitLocationService.delete(id);
                            this.showToaster(messages.visitLocationValidation.visitLocationDeleteSuccess, toasterType.success);
                            this.$emit('closeForm');
                            const rolePromise = [];
                            this.form.managers.forEach(val => {
                                rolePromise.push(UserService.deleteRoleData(val.id, id));
                            });
                            Promise.all(rolePromise);
                        }
                    } catch (err) {
                        this.showToaster(messages.visitLocationValidation.visitLocationDeleteFailed, toasterType.error);
                    } finally {
                        this.spinner = false;
                    }
                },
                acceptClass: 'delete-popup-accept-btn',
                rejectClass: 'p-button-outlined'
            });
        },
        toggleSlots() {
            this.showSlots = !this.showSlots;
        },
        addSlots(name, days) {
            this.form.purposeList.push({ purposeId: uuidv4(), name: name, duration: days });
        },
        removeSlot(index) {
            this.form.purposeList.splice(index, 1);
        },
        managerSelectionDone(data) {
            const val = data.map(x => ({ id: x.id, name: x.name, email: x.email }));
            this.form.managers = val;
            this.toggleManagerList();
        },
        toggleManagerList() {
            this.showManagerList = !this.showManagerList;
        },
        removeManager(index) {
            this.form.managers.splice(index, 1);
        },
        onRowSelectUnSelect(data) {
            this.form.selectedCoveredLocation = data;
        },
        isValidEmail(email) {
            if (email.match(regexEmail)) {
                return true;
            } else {
                return;
            }
        }
    }
};
</script>
<style scoped>
#plusButton {
    width: 2.813rem;
    float: right;
}
#crossButton {
    height: 1.688rem;
}
.slots-div {
    width: 100%;
    margin-top: 2rem;
}
.panelgrid td {
    max-width: 21rem;
    word-wrap: break-word;
}
</style>
<style>
.p-progress-spinner-svg {
    width: 100px !important;
}
.p-dropdown-panel.dropdown-panel {
    width: 6vw !important;
}
</style>
