<template>
    <DataTable
        class="covered-location-table ms-n2"
        editMode="row"
        :value="coveredLocation"
        v-model:selection="selectedLocation"
        v-model:editingRows="selectedLocation"
        :rowClass="rowClass"
        dataKey="locationId"
        responsiveLayout="scroll"
    >
        <Column>
            <template #header>
                <div class="field-checkbox me-2">
                    <Checkbox @input="selectAll" :binary="true" v-model="allSelected" />
                </div> Covered
            </template>
            <template #body="{ data }">
                <div class="field-checkbox" v-if="!alreadyUsed(data)">
                    <Checkbox @input="selectLocation" :value="data" v-model="selectedLocation" />
                </div>
            </template>
        </Column>
        <Column field="locationId" header="Name">
            <template #body="{ data }">
                {{`${data.locationId} - ${data.locationName}`}}
            </template>
        </Column>
        <Column field="spoc.name">
            <template #header>
                SPOC Name <Button v-if="selectedLocation.length>0" class="p-button-text"
                icon="pi pi-ellipsis-h" id="setName" @click="showHideSPOC('name')" />
            </template>
            <template #editor="{ data }">
                <InputText v-model="data.spoc.name" v-if="!alreadyUsed(data)"/>
            </template>
        </Column>
        <Column field="spoc.email">
            <template #header>
                Email <Button v-if="selectedLocation.length>0" class="p-button-text"
                icon="pi pi-ellipsis-h" id="setEmail" @click="showHideSPOC('email')" />
            </template>
            <template #editor="{ data }">
                <InputText v-model="data.spoc.email" v-if="!alreadyUsed(data)"/>
            </template>
        </Column>
        <Column field="spoc.phone">
            <template #header>
                Phone <Button v-if="selectedLocation.length>0" class="p-button-text"
                icon="pi pi-ellipsis-h" id="setPhone" @click="showHideSPOC('phone')" />
            </template>
            <template #editor="{ data }">
                <InputMask v-model="data.spoc.phone" mask="+1-999-999-9999" v-if="!alreadyUsed(data)"/>
            </template>
        </Column>
        <Dialog v-if="showSPOC.name"
        :visible="true"
        :modal="true"
        :closable="false"
        :style="{ width: '30vw' }">
            <form>
             <div class="mb-2">
                <label class="fw-bold mb-2">SPOC Name</label>
                    <input
                        id="SPOCName"
                        v-model="spoc.name"
                        type="text"
                        class="w-70 p-inputtext-sm form-control p-2"
                        placeholder="Enter Name"
                    />
             </div>
            </form>
            <template #footer>
                <Button class="me-1" label="APPLY" @click="onSaveSPOC('name', spoc.name)"/>
                <Button label="CANCEL" @click="showHideModal('name')" class="me-3 p-button-outlined" />
            </template>
        </Dialog>
        <Dialog v-if="showSPOC.email"
        :visible="true"
        :modal="true"
        :closable="false"
        :style="{ width: '30vw' }">
            <form>
             <div class="mb-2">
                <label class="fw-bold mb-2">SPOC Email</label>
                    <input
                        id="SPOCEmail"
                        v-model="v$.spoc.email.$model"
                        type="text"
                        class="w-70 p-inputtext-sm form-control p-2"
                        placeholder="Enter Email"
                    />
                    <span
                        class="text-danger"
                        v-if="
                            v$.spoc.email.$error &&
                            v$.spoc.email.email &&
                            v$.spoc.email.email.$invalid
                        "
                    >
                        {{ messages.visitLocationSpocValidation.spocEmailInvalid }}
                    </span>
             </div>
            </form>
            <template #footer>
                <Button class="me-1"  label="APPLY" @click="onSaveSPOC('email', spoc.email)"/>
                <Button label="CANCEL" @click="showHideModal('email')" class="me-3 p-button-outlined" />
            </template>
        </Dialog>
        <Dialog v-if="showSPOC.phone"
        :visible="true"
        :modal="true"
        :closable="false"
        :style="{ width: '30vw' }">
            <form>
             <div class="mb-2">
                <label class="fw-bold mb-2">SPOC Phone</label>
                    <InputMask
                        class="w-70 p-inputtext-sm form-control p-2"
                        v-model="spoc.phone"
                        mask="+1-999-999-9999"
                    />
             </div>
            </form>
            <template #footer>
                <Button class="me-1"  label="APPLY" @click="onSaveSPOC('phone', spoc.phone)"/>
                <Button label="CANCEL" @click="showHideModal('phone')" class="me-3 p-button-outlined" />
            </template>
        </Dialog>
    </DataTable>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import InputMask from 'primevue/inputmask';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { email } from '@vuelidate/validators';
import { messages } from '../../../../shared/constants/constants';


export default {
    name: 'CoveredLocation',
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Checkbox,
        Column,
        DataTable,
        InputMask,
        InputText,
        Button,
        Dialog
    },
    data() {
        return {
            messages,
            showSPOC: { name: false, email: false, phone: false },
            selected: [],
            allSelected: null,
            selectedLocation: this.selectedCoveredLocation,
            spoc: { name: '', email: '', phone: '' }
        };
    },
    validations() {
        return {
            spoc: {
                email: { email }
            }
        };
    },
    watch: {
        selectedCoveredLocation: {
            handler() {
                this.selectedLocation = this.selectedCoveredLocation;
            }
        },
        locationList: {
            handler() {
                this.allSelected = this.selectedCoveredLocation.length != 0 &&
                this.selectedLocation.length == this.locationList.length;
            }
        }
    },
    computed: {
        coveredLocation() {
            return this.locationList.map(
                ({ id, name }) =>
                    this.selectedCoveredLocation.find(x => x.locationId === id) || {
                        locationId: id,
                        locationName: name,
                        spoc: {
                            name: '',
                            email: '',
                            phone: ''
                        }
                    }
            );
        }
    },
    props: {
        locationList: Array,
        visitLocationBuilding: Array,
        selectedCoveredLocation: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        showHideSPOC(spocLabel) {
            this.showSPOC[spocLabel] = true;
        },
        async onSaveSPOC(property, value) {
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            }
            for (const location of this.selectedLocation) {
                location.spoc[property] = value;
            }
            this.showHideModal(property);
        },
        showHideModal(property) {
            this.showSPOC[property] = !this.showSPOC[property];
            this.spoc[property]='';
        },
        async selectAll() {
            this.selectedLocation = this.allSelected ? this.coveredLocation : [];
            this.selectLocation();
        },
        selectLocation() {
            this.allSelected = this.selectedLocation.length == this.locationList.length;
            setTimeout(() => {
                this.$emit('onRowSelectUnSelect', this.selectedLocation);
            }, 50);
        },
        rowClass(data) {
            const alreadyUsed = this.alreadyUsed(data);
            return alreadyUsed ? 'text-danger' : '';
        },
        alreadyUsed({ locationId }) {
            return this.visitLocationBuilding.find(
                val => val.coveredLocations && val.coveredLocations.some(x => x.locationId === locationId)
            );
        }
    }
};
</script>
