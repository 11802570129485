<template>
    <Dialog :visible="true" header='Purpose Details' :closable="false" :style="{ width: '35vw' }" modal>
        <div class="row">
            <div class="col-md-5 col-sm-5">
                <label class="fw-bold" for="purposeName">Purpose</label>
            </div>
            <div class="col-md-6 col-sm-6">
                <InputText class="w-100" id="purposeName" type="text" v-model="v$.name.$model" />
                <span v-if="v$.name.$error && v$.name.required && v$.name.required.$invalid" class="text-danger">
                    {{ messages.purposeValidation.purposeNameRequired }}
                </span>
                <span v-else-if="v$.name.$error && (v$.name.purposeNameExist && v$.name.purposeNameExist.$invalid)" class="text-danger">
                    {{messages.purposeValidation.purposeNameAlreadyExists}}
                </span>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-5 col-sm-5">
                <label class="fw-bold" for="duration">Duration (days)</label>
            </div>
            <div class="col-md-6 col-sm-6">
                <InputNumber class="w-100" id="duration" v-model="v$.duration.$model" />
                <span v-if="v$.duration.$error && v$.duration.required && v$.duration.required.$invalid" class="text-danger">
                    {{ messages.purposeValidation.purposeDurationRequired }}
                </span>
            </div>
        </div>
        <template #footer>
            <Button label="CANCEL" @click="this.$emit('closePopup')" class="p-button-outlined float-end me-3" />
            <Button label="OK" @click="Save()" class="float-end me-3" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import { messages } from '../constants/constants';
const { withAsync } = helpers;
import Utility from '../utility/utility';

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'RequiredSlotPopup',
    components: {
        Button,
        Dialog,
        InputNumber,
        InputText
    },
    props: {
        slotList: Array
    },
    created() {
        this.slotList.map(x => {
            this.purposeName.push(Utility.removeInBetweenSpaces(x.name).toLowerCase().trim());
        });
    },
    data() {
        return {
            messages,
            name: null,
            nameErrorMessage: '',
            duration: null,
            durationErrorMessage: '',
            purposeName: []
        };
    },
    validations() {
        return {
            name: { required, purposeNameExist: withAsync(this.isUniqueName) },
            duration: { required }
        };
    },
    methods: {
        isUniqueName() {
            const tempName = Utility.removeInBetweenSpaces(this.name).toLowerCase().trim();
            return !this.purposeName.includes(tempName);
        },
        async Save() {
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            } else {
                this.$emit('SlotDetails', this.name, this.duration);
            }
        }
    }
};
</script>
