<template>
    <label class="fw-bold">Purposes<span class="text-danger ms-1">*</span></label>
    <Button icon="pi pi-plus" id="plusButton" @click="toggleSlots()" />
    <table class="slots-div panelgrid">
        <tr class="mb-1" v-for="(slot, index) in SlotsList" :key="index">
            <td class="w-50">{{ slot.name }}</td>
            <td><span class="ms-3">{{ slot.duration }}</span></td>
            <td>
                <Button
                    icon="pi pi-minus"
                    @click="removeSlot(index)"
                    class="p-button-rounded p-button-danger p-button-text float-end"
                    id="crossButton"
                />
            </td>
        </tr>
    </table>
    <RequiredSlotPopup v-if="showSlots" @closePopup="toggleSlots" @SlotDetails="SlotDetails" :slotList="SlotsList"/>
</template>

<script>
import RequiredSlotPopup from '../../../../shared/popup/RequiredSlotsPopup.vue';
import Button from 'primevue/button';

export default {
    name: 'RequiredSlots',
    components: {
        RequiredSlotPopup,
        Button
    },
    props: {
        SlotsList: Array
    },
    data() {
        return {
            showSlots: false
        };
    },
    methods: {
        toggleSlots() {
            this.showSlots = !this.showSlots;
        },
        SlotDetails(name, days) {
            this.$emit('addSlots', name, days);
            this.toggleSlots();
        },
        removeSlot(index) {
            this.$emit('removeSlot', index);
        }
    },
    emits: ['removeSlot', 'addSlots']
};
</script>
<style scoped>
#plusButton {
    width: 2.813rem;
    float: right;
}
.slots-div {
    width: 100%;
    margin-top: 2rem;
}
.panelgrid td{
    max-width: 8rem;
    word-wrap: break-word;
}
</style>
