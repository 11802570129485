<template>
    <div class="container container-width">
        <div class="text-end" v-if="isNavigationAllowed(NAVIGATIONCONST.ADDVISITLOCATION)">
            <Button
                class="button-color-lavender-purple mt-2 create-new-button"
                label="CREATE A VISIT LOCATION"
                @click="toggleCreateEditModal"
            />
        </div>
            <DataTable
                class="mt-3"
                :value="visitLocations"
                v-model:selection="selectedvisitLocation"
                selectionMode="single"
                dataKey="id"
                :paginator="true"
                :rows="rows"
                :rowsPerPageOptions="rowPerPageOptions"
                :loading="loading"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                responsiveLayout="scroll"
                sortField="name"
                :sortOrder="sortOrder.Ascending"
            >
                <template #header>
                    <div class="table-header">
                        <Button class="p-button-text" @click="showFilterPopup = true">
                            <img src="../../../../assets/filter-icon.png" />
                            <span class="ms-2 color-lavender-purple">Filter</span>
                        </Button>
                    </div>
                </template>
                <Column class="table-column-title" field="name" header="Name" sortable>
                    <template #body="{ data }">
                        <span
                            class="ms-0 legend-key"
                            :class="data.state === VisitLocationState.Active ? 'light-green-background' : 'gray-background'"
                        ></span>
                        <span class="table-first-column" @click="onTitleClick(data)" data-bs-toggle="tooltip"
                            data-bs-placement="top" :title="data.name">
                            {{ data.name }}
                        </span>
                    </template>
                </Column>
                <Column field="boroughId" header="Borough" sortable></Column>
                <Column field="districtId" header="District" sortable>
                    <template #body="{ data }"> {{ data.districtId }}: {{ data.districtName }} </template>
                </Column>
                <Column field="buildingId" header="Building" sortable>
                    <template #body="{ data }"> {{ data.buildingId }}: {{ data.buildingName }} </template>
                </Column>
                <Column field="coveredLocations" header="Locations" sortable>
                    <template #body="{ data }">
                        {{ data.coveredLocations?.length || 0 }}
                    </template>
                </Column>
                <template #paginatorstart></template>
            </DataTable>
    </div>
    <CreateVisitLocation
        v-if="showCreateEditModal"
        @closeForm="(visitLocationData = null, showCreateEditModal = false)"
        :visitLocationData="visitLocationData"
        :visitLocations="visitLocations"
        :boroughList="boroughList"
        :districtList="districtList"
    />
    <VisitLocationDetails v-else-if="showDetailModal" @toggleModal="onShowDetails" :visitLocationDetail="visitLocationDetail" />
    <VisitLocationFilter :showFilterPopup="showFilterPopup" @closeFilter="showFilterPopup = false" @applyFilter="applyFilter" />
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { BoroughService, DistrictService, VisitLocationService } from '../../../../apis';
import VisitLocationDetails from '../visit_location-details/VisitLocationDetails.vue';
import { VisitLocationState } from '../../../../shared/enums';
import { rows, rowPerPageOptions, tableIconColor, NAVIGATIONCONST, messages, toasterTime, sortOrder } from '../../../../shared/constants';
import { isNavigationAllowed } from '../../../../shared/utils';
import CreateVisitLocation from '../create-visit-locations/CreateVisitLocation.vue';
import VisitLocationFilter from './VisitLocationFilter.vue';
import { FilterMatchMode } from 'primevue/api';
export default {
    name: 'AllVisitLocations',
    components: {
        VisitLocationDetails,
        DataTable,
        Column,
        Button,
        CreateVisitLocation,
        VisitLocationFilter
    },
    data() {
        return {
            sortOrder,
            rows: rows,
            rowPerPageOptions: rowPerPageOptions,
            tableIconColor: tableIconColor,
            isNavigationAllowed,
            NAVIGATIONCONST,
            loading: false,
            VisitLocationState,
            visitLocations: null,
            showDetailModal: false,
            showFormModal: false,
            visitLocationDetail: null,
            selectedvisitLocation: null,
            showCreateEditModal: false,
            visitLocationData: null,
            showFilterPopup: false,
            filters: {
                state: { value: null, matchMode: FilterMatchMode.EQUALS },
                buildingId: { value: [], matchMode: FilterMatchMode.IN }
            }
        };
    },
    async mounted() {
        await this.fetchVisitLocations();
    },

    methods: {
        async fetchVisitLocations() {
            try {
                this.visitLocations = await VisitLocationService.getSnapshot(this.filters);
            } catch (err) {
                this.$toast.add({
                    severity: 'error',
                    closable: false,
                    detail: messages.visitLocationValidation.visitLocationFetchingFailed,
                    life: toasterTime
                });
            }
        },
        async getAllBorough() {
            this.boroughList = this.boroughList || (await BoroughService.getAll({ isInScope: { matchMode: '==', value: 1 } }));
        },
        async getAllDistricts() {
            this.districtList = this.districtList || (await DistrictService.getAll({ isInScope: { matchMode: '==', value: 1 } }));
        },
        onShowDetails(data) {
            this.visitLocationDetail = data ? data : null;
            this.showDetailModal = !this.showDetailModal;
        },
        onTitleClick(data) {
            if (isNavigationAllowed(NAVIGATIONCONST.EDITVISITLOCATION)) {
                const locationData = _cloneDeep(data);
                this.editVisitLocation(locationData);
            } else if (isNavigationAllowed(NAVIGATIONCONST.VIEWVISIT)) {
                this.onShowDetails(data);
            }
        },
        async editVisitLocation(visitLocationData) {
            this.visitLocationData = visitLocationData;
            if (!this.showCreateEditModal) {
                await Promise.all([this.getAllBorough(), this.getAllDistricts()]);
            }
            this.showCreateEditModal = !this.showCreateEditModal;
        },
        applyFilter(state, { buildings }) {
            this.filters.state.value = state;
            this.filters.buildingId.value = buildings;
            this.fetchVisitLocations();
            this.showFilterPopup = false;
        },
        async toggleCreateEditModal() {
            if (!this.showCreateEditModal) {
                await Promise.all([this.getAllBorough(), this.getAllDistricts()]);
            }
            this.showCreateEditModal = !this.showCreateEditModal;
        }
    }
};
</script>
